import React, { createRef } from "react";

import { Dialog } from "primereact/dialog";

// CSS  imports //
import logo from "../../components/img/logo.png";

// Common file imports //
import AppConfig from "../../modals/AppConfig";
import { CheckUserName } from "../../common/Validation";
import User from "../../modals/User";

// Api file imports //
import {
  getEventMailTemplate,
  updateEventMailTemplate,
  createEventMailTemplate,
} from "../../libraries/event";
import Notifications from "../../common/Notifications";
import { AiOutlineCloseCircle } from "react-icons/ai";
import EmailEditor from "react-email-editor";
import { UploadImage } from "../../common/UploadDoc";

class EventMailTemplateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templateName: "",
      description: "",
      code: "",
      html: "",
      design: null,
      variables: [],
      loading: true,
    };
    this.emailEditorRef = createRef(null);
  }

  async componentDidMount() {}

  async componentDidUpdate(prevProps) {
    const id = this.props.editTemplateId;
    if (this.props.status !== prevProps.status && id && this.props.status) {
      this.setState({ status: this.props.status });
      if (id) {
        const response = await getEventMailTemplate(id);
        if (response) {
          const { status, result } = response;
          if (status === "success" && result) {
            this.setState({
              templateName: result.name,
              description: result?.description,
              code: result?.code,
              html: result?.html,
              design: JSON.parse(result?.json),
              json: result.json,
              variables: result?.variables ? JSON.parse(result?.variables) : [],
            });
            const { editor } = this.emailEditorRef.current;
            if (editor) editor.loadDesign(JSON.parse(result?.json));
          }
        }
      }
    }
  }

  handleClose = () => {
    Notifications.setDocType("");
    Notifications.setDocTypeError(0);
    Notifications.setMediaSizeImg("");
    Notifications.setMediaSizeDoc("");
    this.setState(
      {
        templateName: "",
        description: "",
        code: "",
        html: "",
        variables: [],
      },
      () => {
        this.props.closeModel(false);
      }
    );
  };

  validateTemplateName = () => {
    const templateNameError = CheckUserName(this.state.templateName);
    if (templateNameError === 1) {
      this.setState({ templateNameError: "Field empty" });
      return false;
    } else return true;
  };

  validateHtml = () => {
    if (this.state.html.length > 0) {
      return true;
    }
    this.setState({ htmlError: "Field empty" });
    return false;
  };

  // Empty input validation
  ValidateAll = () => {
    const templateNameInput = this.validateTemplateName();
    // const htmlInput = this.validateHtml();

    const result = templateNameInput;
    // && htmlInput;
    if (!result) AppConfig.showValidationError();
    return result;
  };

  // on submit sign in function
  onSubmitCreate = async (e) => {
    e.preventDefault();
    const emailEditorRef = this.emailEditorRef.current?.editor;

    emailEditorRef?.exportHtml(async (data) => {
      const { design, html } = data;
      this.setState({ design, json: JSON.stringify(design), html });

      // const id = User.user_id;
      const editId = this.props.editTemplateId;
      const allValidation = this.ValidateAll();
      if (allValidation) {
        const requestData = {
          name: this.state.templateName,
          description: this.state?.description ? this.state?.description : null,
          code: this.state?.code ? this.state?.code : null,
          html,
          json: JSON.stringify(design),
          variables:
            this.state?.variables?.length > 0
              ? JSON.stringify(this.state?.variables)
              : null,
        };
        requestData["user_id"] = User.user_id;
        if (editId) {
          const id = this.props.editTemplateId;
          const response = await updateEventMailTemplate(requestData, id);
          if (response && response.status === "success") {
            this.handleClose();
            AppConfig.setMessage("Template updated successfully", false);
          } else if (response.status === "error") {
            this.handleClose();
            AppConfig.setMessage(response?.result);
          }
        } else {
          const response = await createEventMailTemplate(requestData);
          if (response && response.status === "success") {
            this.handleClose();
            User.setRefresh(true);
            AppConfig.setMessage("Template Created successfully", false);
          } else if (response.status === "error" || response.status === "0") {
            let message = "";
            if ("result" in response) {
              message = response.result.error[0];
            } else {
              message = Object.keys(response.error)[0];
            }
            AppConfig.setMessage(message);
          }
        }
        if (this.props?.afterSubmit) await this.props.afterSubmit();
      }
      return false;
    });
  };

  render() {
    // const requiredMark = <span className="asterisk">*</span>;
    return (
      <div>
        <Dialog
          visible={this.props.status}
          onHide={this.handleClose}
          header={
            <div className="form-head w-100 d-flex jc-sb align-center">
              <div className="w-100 d-flex align-center">
                <img src={logo} alt="logo" style={{ maxHeight: "50px" }} />
                {this.props.editTemplateId ? (
                  <h3> Update Template</h3>
                ) : (
                  <h3> Create Template</h3>
                )}
              </div>
              <button
                className="popup-button closeText d-flex"
                onClick={this.handleClose}
              >
                <span>
                  <AiOutlineCloseCircle />
                </span>
              </button>
            </div>
          }
        >
          <form onSubmit={this.onSubmitCreate}>
            <div className="row">
              <div className="col-12 col-md-6">
                <label className="d-flex align-center">
                  Template Name <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    this.state.templateNameError ? "validationError" : ""
                  }`}
                  id="templateName"
                  placeholder={
                    this.state.templateNameError || "Enter the Template Name"
                  }
                  value={this.state.templateName}
                  onChange={(e) =>
                    this.setState({
                      templateName: e.target.value,
                      templateNameError: "",
                    })
                  }
                  onFocus={() => {
                    this.setState({ templateNameError: "" });
                  }}
                />
              </div>
              <div className="col-12 col-md-6">
                <label className="d-flex align-center">Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="templateCode"
                  placeholder="Enter the Template Code"
                  value={this.state.code}
                  onChange={(e) =>
                    this.setState({
                      code: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-12">
                <label>Description</label>
                <textarea
                  className="form-control text-box mb-3"
                  id="eventDescription"
                  label="Event Description"
                  placeholder="Event Description"
                  type="textarea"
                  onFocus={() => {
                    this.setState({ descriptionError: "" });
                  }}
                  style={{ height: "100px" }}
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                />
              </div>
              <div className="col-12">
                <EmailEditor
                  ref={this.emailEditorRef}
                  onReady={(editor) => {
                    this.setState({ loading: false });
                    editor.registerCallback("image", async (file, done) => {
                      await UploadImage(file.attachments[0], (response = false) => {
                        if (response && response.status === "success") {
                          done({
                            progress: 100,
                            url: response.result.url,
                          });
                        }
                      });
                    });
                  }}
                  setValue={(html) => this.setState({ html })}
                />
              </div>
            </div>

            {this.props?.saveAccess && (
              <div className="d-flex justify-content-between align-items-center my-3">
                <button
                  type="button"
                  className="btn  event-cta-trans"
                  onClick={this.handleClose}
                >
                  Cancel
                </button>
                <button type="submit" className="btn  event-cta">
                  {this.props.editTemplateId
                    ? "Update Template"
                    : "Create Template"}
                </button>
              </div>
            )}
          </form>
        </Dialog>
      </div>
    );
  }
}
export default EventMailTemplateForm;
