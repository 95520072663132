import React from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { CommonTable, TableGlobalSearch } from "../../common/CommonElements";
import moment from "moment";
import AppLayoutConfig from "../../common/AppLayoutConfig";
import { checkPermission, momentDateTimeFormat } from "../../common/Common";
import { Button } from "primereact/button";
import ConfirmModal from "../../components/ConfirmModal";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  deleteEventMailTemplate,
  eventMailTemplateTable,
} from "../../libraries/event";
import User from "../../modals/User";
import AppConfig from "../../modals/AppConfig";
import { Tag } from "primereact/tag";
import EventMailTemplateForm from "./EventMailTemplateForm";

export default class EventMailTemplateDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // new
      templateData: [],
      loading: true,
      globalFilter: "",
      // Access Rights
      writeAccess: checkPermission("WRITE_EVENT"),
    };
    AppLayoutConfig.setShowLayout(true);
    AppLayoutConfig.setShowHeader(true);
    AppLayoutConfig.setShowSidebar(true);
    AppLayoutConfig.setShowFooter(true);
    AppLayoutConfig.setShowSideCalendar(true);
    AppLayoutConfig.setShowChat(true);
  }

  componentDidMount = async () => {
    await this.tableFetchApi();
  };

  tableFetchApi = async () => {
    this.setState({ loading: true });
    const { filter } = this.state;
    const response = await eventMailTemplateTable({ filter });
    if (response) {
      const { result: oldTemplateData } = response;
      if (oldTemplateData && Array.isArray(oldTemplateData)) {
        const templateData = [];
        oldTemplateData.forEach((row) => {
          templateData.push({
            ...row,
            from_date: row?.from_date
              ? moment(row?.from_date).format(momentDateTimeFormat)
              : row?.from_date,
            to_date: row?.to_date
              ? moment(row?.to_date).format(momentDateTimeFormat)
              : row?.to_date,

            event_start_date: row?.from_date
              ? moment(row?.from_date).format("DD MMM YY  hh:mm A")
              : row?.from_date,
          });
        });
        this.setState({ templateData });
      }
    }
    this.setState({ loading: false });
  };

  editTemplate = (id) => {
    this.setState({ editTemplateId: id, status: true });
  };

  requestDeleteTemplate = (id) => {
    this.setState({ visible: true, deleteId: id });
  };

  deleteTemplate = async () => {
    const id = this.state.deleteId;
    await deleteEventMailTemplate(id).then(async (response) => {
      if (response && response.status === "success") {
        User.setRefresh(true);
        await this.tableFetchApi();
        this.setState({ visible: false });
        AppConfig.setMessage(response.result, false);
      }
    });
  };

  render() {
    const { templateData: tableValue, loading, globalFilter } = this.state;
    const columns = [
      <Column
        field="id"
        header="SL"
        sortable
        // filter
        filterPlaceholder="Search by SL"
        style={{ minWidth: "3rem", maxWidth: "4rem" }}
      />,
      <Column
        field="name"
        header="Template"
        sortable
        filter
        filterPlaceholder="Search by Template"
        style={{
          minWidth: "20rem",
          maxWidth: "23rem",
        }}
        body={(data) => (
          <>
            <Tooltip
              target={`.event-template-title${data?.id}`}
              content={data?.name}
            />
            <div
              className={`event-template-title${data?.id}`}
              data-toggle="tooltip"
              title={data.name}
            >
              {data?.name && data?.name?.length > 36
                ? data?.name.slice(0, 35) + "..."
                : data?.name}
            </div>
          </>
        )}
      />,
      <Column
        field="description"
        header="Description"
        sortable
        filter
        filterPlaceholder="Search by Description"
        style={{
          minWidth: "20rem",
          maxWidth: "23rem",
        }}
        body={(data) => {
          return (
            <>
              <Tooltip
                target={`.event-template-description${data?.id}`}
                content={data?.description}
              />
              <div
                className={`event-template-description${data?.id}`}
                data-toggle="tooltip"
                title={data.description}
              >
                {data?.description && data?.description?.length > 35
                  ? data?.description.slice(0, 35) + "..."
                  : data?.description}
              </div>
            </>
          );
        }}
      />,
      <Column
        field="approved"
        header="Approval"
        sortable
        style={{
          minWidth: "20rem",
          maxWidth: "23rem",
        }}
        body={(data) => {
          return (
            <>
              {data?.approved ? (
                <Tag
                  severity="success"
                  value="Approved"
                  className="rounded-pill"
                />
              ) : (
                <Tag
                  severity="warning"
                  value="Pending"
                  className="rounded-pill"
                />
              )}
            </>
          );
        }}
      />,
      <Column
        header="Action"
        align="center"
        headerClassName="d-flex justify-content-center"
        style={{ minWidth: "30rem", maxWidth: "33rem" }}
        body={(data) => {
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex">
                <div className="col d-flex align-items-center justify-content-center">
                  <MdEdit
                    size={40}
                    color="white"
                    onClick={() => this.editTemplate(data.id)}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#464eb8",
                      borderRadius: 20,
                      borderColor: "white",
                      borderWidth: 1,
                      padding: 8,
                    }}
                  />
                </div>
                {this.state?.writeAccess && (
                  <div className="col d-flex align-items-center justify-content-center">
                    <MdDelete
                      size={40}
                      color="white"
                      onClick={() => this.requestDeleteTemplate(data.id)}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#464eb8",
                        borderRadius: 20,
                        borderColor: "white",
                        borderWidth: 1,
                        padding: 8,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        }}
      />,
    ];
    return (
      <>
        <div style={{ overflowX: "scroll" }}>
          <div className="app-main__outer container-fluid">
            <div className="back-event  m-3">
              <button onClick={() => this.props.history.goBack()}>
                <BsArrowLeftShort /> Event Mail Templates
              </button>
            </div>
            <div className="content">
              <div className="d-flex mb-2 align-items-center justify-content-between">
                <div className="col-auto d-flex align-items-center">
                  <TableGlobalSearch
                    value={globalFilter}
                    onChange={(value) => this.setState({ globalFilter: value })}
                  />
                </div>
                <div className="col d-flex justify-content-end">
                  {this.state.writeAccess && (
                    <>
                      <div className="d-none d-md-block col-auto create-event p-0 m-0 ml-2">
                        <Button
                          onClick={() =>
                            this.setState({ status: true, editEventId: "" })
                          }
                        >
                          Create Mail Template
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="table">
                <CommonTable
                  value={tableValue}
                  loading={loading}
                  globalFilterFields={["id", "name", "description"]}
                  globalFilter={globalFilter}
                >
                  {columns}
                </CommonTable>
              </div>
              <EventMailTemplateForm
                status={this.state.status}
                editTemplateId={this.state.editTemplateId}
                closeModel={() => this.setState({ status: false })}
                afterSubmit={this.tableFetchApi}
                saveAccess={this.state.writeAccess}
              />
              <ConfirmModal
                delete={true}
                visible={this.state.visible}
                heading="Delete"
                title="Are you sure you want to delete the Event?"
                confirm={() => this.deleteTemplate()}
                handleClose={() => this.setState({ visible: false })}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
