export const filterDropdownList = [
    {
        value: "one week",
        label: "one week",
    },
    {
        value: "15 days",
        label: "15 days",
    },
    {
        value: "30 days",
        label: "30 days",
    },
    {
        value: "6 months",
        label: "6 months",
    },
    {
        value: "one year",
        label: "one year",
    },
    {
        value: "more than one year",
        label: "more than one year",
    },
];