// https://vcat.co.in/staging/vcat-api/api/v1/

// https://vcat.co.in/staging/vcat-api/api/v1/internal_home/auth_user/1
// https://vcat.co.in/staging/vcat-api/api/v1/internal_home/auth_user/1https://vcat.co.in/staging/vcat-api/api/v1/events/dropdowns
import { callApi } from "./Api";
import "./Global";
// import AppConfig from '../modals/AppConfig';
/**
 *
 * @param{email, password)data
 */
export const event = async (requestData = {}) => {
  const functionUrl = "events/insert";
  try {
    return callApi(functionUrl, requestData, "POST")
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

export const events = async (requestData = {}) => {
  const functionUrl = "events_all";

  try {
    return callApi(functionUrl, requestData, "POST")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
export const deleteEvent = async (id) => {
  const functionUrl = "events/delete";
  try {
    return callApi(functionUrl, {}, "DELETE", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
// users
export const EventUpdate = async (requestData, id) => {
  const functionUrl = "events/update";
  try {
    return callApi(functionUrl, requestData, "PUT", id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

export const EventAutoPopulate = async (id) => {
  const functionUrl = "events";
  try {
    return callApi(functionUrl, {}, "GET", id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
export const eventFormDropdown = async (requestData = {}) => {
  const functionUrl = "events/dropdown/wings";
  try {
    return callApi(functionUrl, requestData, "GET")
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

export const eventTable = async (requestData = {}) => {
  const functionUrl = "events/get";
  try {
    return callApi(functionUrl, requestData, "POST")
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
export const saveEventGallery = async (requestData, id) => {
  const functionUrl = "events/updateGallery";
  try {
    return callApi(functionUrl, requestData, "PUT", id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

export const getEventSpeakers = async (id) => {
  const functionUrl = "events/speakers";
  try {
    return callApi(functionUrl, {}, "GET", id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

export const saveEventSpeaker = async (requestData = {}) => {
  const functionUrl = "events/saveEventSpeaker";

  try {
    return callApi(functionUrl, requestData, "POST")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
export const updateEventSpeaker = async (requestData, id) => {
  const functionUrl = "events/updateEventSpeaker";
  try {
    return callApi(functionUrl, requestData, "PUT", id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
export const deleteEventSpeaker = async (id) => {
  const functionUrl = "events/deleteEventSpeaker";
  try {
    return callApi(functionUrl, {}, "DELETE", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
export const notifyEventMembers = async (id) => {
  const functionUrl = "notify/events";
  try {
    return callApi(functionUrl, {}, "GET", id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};


export const eventMailTemplateTable = async (requestData = {}) => {
  const functionUrl = "event-mail-template";
  try {
    return callApi(functionUrl, requestData, "GET")
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

export const deleteEventMailTemplate = async (id) => {
  const functionUrl = "event-mail-template/delete";
  try {
    return callApi(functionUrl, {}, "DELETE", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

export const getEventMailTemplate = async (id) => {
  const functionUrl = "event-mail-template/get";
  try {
    return callApi(functionUrl, {}, "GET", id)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

export const updateEventMailTemplate = async (requestData, id) => {
  const functionUrl = "event-mail-template/update";
  try {
    return callApi(functionUrl, requestData, "PUT", id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
    } catch (error) {
      console.log("Error from catch => ", error);
    }
  };
  
  export const createEventMailTemplate = async (requestData) => {
    const functionUrl = "event-mail-template/insert";
    try {
      return callApi(functionUrl, requestData, "POST")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
    } catch (error) {
      console.log("Error from catch => ", error);
    }
  };
  
  export const createSendMailRequest = async (requestData) => {
    const functionUrl = "events/sendEventMails";
    try {
      return callApi(functionUrl, requestData, "POST")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error from API => ", error);
      });
    } catch (error) {
      console.log("Error from catch => ", error);
    }
  };

  export const eventFormLookups = async () => {
    const functionUrl = "event-form/lookups";
    try {
      return callApi(functionUrl, {}, "GET")
        .then((response) => {
          console.log(response);
          return response;
        })
        .catch((error) => {
          console.log("Error from API => ", error);
        });
    } catch (error) {
      console.log("Error from catch => ", error);
    }
  };