import React from "react";
import { observer } from "mobx-react";
import moment from "moment";

// Components imports //
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import Modal from "react-bootstrap/Modal";
import {
  CommonTable,
  TableFilterDropdown,
  TableGlobalSearch,
  TableTabs,
} from "../../common/CommonElements";
import ConfirmModal from "../../components/ConfirmModal";
import EventGallery from "./EventGallery";
import EventSpeakers from "./EventSpeakers";
import EventsForm from "./EventsForm";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";

// Modal imports //
import User from "../../modals/User";
import AppConfig from "../../modals/AppConfig";
import AppLayoutConfig from "../../common/AppLayoutConfig";

// Api file imports //
import {
  createSendMailRequest,
  deleteEvent,
  eventMailTemplateTable,
  eventTable,
} from "../../libraries/event";

// Services
import { ExportService } from "../../common/ExportService";
import { checkPermission, momentDateTimeFormat } from "../../common/Common";

// CSS  imports
import "./EventDashboard.css";

// Icons
import { BsArrowLeftShort } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";

// Images
import logo from "../../components/img/logo.png";

class EventDashboard extends React.Component {
  exportServices;

  constructor(props) {
    super(props);
    this.state = {
      status: false,
      filter: "30 days",
      editEventId: "",
      deleteId: "",
      // new
      eventData: [],
      loading: true,
      globalFilter: "",
      menu: "Upcoming",
      // Gallery
      showGallery: false,
      eventGalleryId: null,
      eventGalleryTitle: null,
      // Speakers
      showSpeakers: false,
      eventSpeakersId: null,
      eventSpeakersTitle: null,
      // Access Rights
      writeAccess: checkPermission("WRITE_EVENT"),
      // Event Registration
      showEventRegistration: false,
      eventRegistrations: [],
      userList: [],
      // Send Email
      showSendEmail: false,
      eventId: null,
      externalEmailIds: "",
      mailSubject: null,
      mailSchedule: null,
      mailTemplateId: null,
      mailTemplateLookup: [],
    };
    this.exportExcel = this.exportExcel.bind(this);
    this.exportServices = new ExportService();
    AppLayoutConfig.setShowLayout(true);
    AppLayoutConfig.setShowHeader(true);
    AppLayoutConfig.setShowSidebar(true);
    AppLayoutConfig.setShowFooter(true);
    AppLayoutConfig.setShowSideCalendar(true);
    AppLayoutConfig.setShowChat(true);
  }

  componentDidMount = async () => {
    await this.tableFetchApi();
  };

  tableFetchApi = async () => {
    this.setState({ loading: true });
    const { filter } = this.state;
    const response = await eventTable({ filter });
    if (response) {
      const { result: oldEventData } = response;
      if (oldEventData && Array.isArray(oldEventData)) {
        const eventData = [];
        oldEventData.forEach((row) => {
          eventData.push({
            ...row,
            from_date: row?.from_date
              ? moment(row?.from_date).format(momentDateTimeFormat)
              : row?.from_date,
            to_date: row?.to_date
              ? moment(row?.to_date).format(momentDateTimeFormat)
              : row?.to_date,

            event_start_date: row?.from_date
              ? moment(row?.from_date).format("DD MMM YY  hh:mm A")
              : row?.from_date,
          });
        });
        this.setState({ eventData });
      }
    }
    this.setState({ loading: false });
  };

  exportExcel() {
    const { eventData, menu } = this.state;
    if (eventData && eventData.length > 0) {
      const tableData = eventData.filter((row) => {
        const today = new Date(new Date().toDateString());
        const eventDate = new Date(row.from_date);
        if (this.state.menu === "Upcoming") {
          return eventDate >= today;
        } else {
          return eventDate < today;
        }
      });
      const excelData = [];
      tableData.forEach((row) => {
        excelData.push({
          SL: row?.id,
          "Event Name": row?.name || "-",
          "Event Description": row?.description || "-",
        });
      });
      this.exportServices.exportExcel(
        `${menu} Events`,
        [
          { field: "SL", header: "SL" },
          { field: "Event Name", header: "Event Name" },
          { field: "Event Description", header: "Event Description" },
        ],
        excelData
      );
    }
  }

  exportExcelEventRegistrations() {
    const { eventRegistrations } = this.state;
    if (eventRegistrations && eventRegistrations.length > 0) {
      const excelData = [];
      eventRegistrations.forEach((row) => {
        excelData.push({
          Name: row?.name || "-",
          Email: row?.email || "-",
          "Mobile Number": row?.mobile_number || "-",
          "ICAI Membership No": row?.icai_membership_no || "-",
          "VCAT Membership Status": row?.vcat_membership_status || "-",
          "Message or Query": row?.message || "-",
        });
      });
      this.exportServices.exportExcel(
        `Events Registrations`,
        [
          { field: "Name", header: "Name" },
          { field: "Email", header: "Email" },
          { field: "Mobile Number", header: "Mobile Number" },
          { field: "ICAI Membership No", header: "ICAI Membership No" },
          { field: "VCAT Membership Status", header: "VCAT Membership Status" },
          { field: `Message or Query`, header: `Message or Query` },
        ],
        excelData
      );
    }
  }

  handleClick = () => {
    this.setState({ status: true });
  };

  callApiFilter = ({ value: filter }) => {
    this.setState({ filter }, async () => {
      await this.tableFetchApi();
    });
  };

  updateEvent = (id) => {
    this.setState({ editEventId: id, status: true });
  };

  deleteEvent = (id) => {
    this.setState({ visible: true, deleteId: id });
  };

  getSuccess = async () => {
    const id = this.state.deleteId;
    await deleteEvent(id).then(async (response) => {
      if (response && response.status === "success") {
        User.setRefresh(true);
        await this.tableFetchApi();
        this.setState({ visible: false });
        AppConfig.setMessage(response.result, false);
      }
    });
  };

  showGallery(data) {
    this.setState({
      showGallery: true,
      eventGalleryId: data.id,
      eventGalleryTitle: data?.title || data?.name,
    });
  }

  renderGallery() {
    const {
      showGallery: show,
      eventGalleryId: id,
      eventGalleryTitle: title,
    } = this.state;
    const onClose = () => {
      this.setState({ showGallery: false, eventGalleryId: null });
    };
    return (
      <EventGallery
        show={show}
        id={id}
        title={title}
        onClose={onClose}
        saveAccess={this.state.writeAccess}
      />
    );
  }

  showSpeakers(data) {
    this.setState({
      showSpeakers: true,
      eventSpeakersId: data.id,
      eventSpeakersTitle: data?.title || data?.name,
    });
  }

  renderSpeakers() {
    const {
      showSpeakers: show,
      eventSpeakersId: id,
      eventSpeakersTitle: title,
    } = this.state;
    const onClose = () => {
      this.setState({ showSpeakers: false, eventSpeakersId: null });
    };
    return (
      <EventSpeakers
        show={show}
        id={id}
        title={title}
        onClose={onClose}
        saveAccess={this.state.writeAccess}
      />
    );
  }

  sendEmailApi = async (emails, data) => {
    const emailIDList = [];

    emails?.map((result) =>
      emailIDList.push({
        To: result?.email,
        Subject: data?.name,
        CustomData: [
          {
            var1: data?.event_type,
            var2: data?.description,
          },
        ],
        TransactionId: "1234",
        Attachments: [
          {
            "FILE1(path)": data?.image,
            "FILE2(path)": data?.image,
            "FILE3(path)": data?.image,
          },
        ],
      })
    );
    console.log(emailIDList, "emailIDList2134123");
    // const currentDate = new Date(); // Get the current date
    if (emailIDList.length > 0) {
      try {
        const response = await fetch(
          "https://vcat.co.in/bulkEmailSendApi.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Api: "345e31e21823a406938448b1c299f877",
              CampaignName: "vcat1",
              From: "info@email.vcat.co.in",
              TemplateId: data?.mail_template,
              CreditType: "1",
              ScheduledDateTime: "2024-01-31 17:35:00",
              data: emailIDList,
            }),
          }
        );

        const responseJson = await response.json();
        console.log("responseJson", responseJson);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  sendMail = async (data) => {
    const { id } = data;
    console.log({ id });
    // const response1 = await All_Users();
    // if (response1) {
    //   const { result: userList, status } = response1;
    //   if (status === "success" && userList) {
    //     const emails = JSON.parse(JSON.stringify(userList?.all_users));
    //     this.sendEmailApi(emails, data);
    //     const response = await notifyEventMembers(id);
    //     if (response) {
    //       const { status, result } = response;
    //       if (status === "success" && result) {
    //         AppConfig.setMessage(result, false);
    //       }
    //     }
    //   }
    // }
  };

  viewRegistrations = ({ registrations }) => {
    this.setState({
      showEventRegistration: true,
      eventRegistrations: registrations,
    });
  };

  renderEventsMenu = () => (
    <ThreeDotsMenu
      items={[
        {
          label: "Create Event",
          command: () => this.setState({ status: true, editEventId: "" }),
          disabled: !this.state.writeAccess,
        },
        {
          label: "Mail Templates",
          url: "/event/mail-template",
          disabled: !this.state.writeAccess,
        },
      ]}
    />
  );

  openSendEmailDialog = (data) => {
    console.log(data);
    this.setState({
      showSendEmail: true,
      eventId: data.id,
      externalEmailIds: "",
      mailSubject: null,
      mailSchedule: null,
      mailTemplateId: null,
      mailTemplateLookup: [],
    });
    eventMailTemplateTable().then(({ result: mailTemplates }) => {
      if (Array.isArray(mailTemplates) && mailTemplates.length > 0) {
        const options = mailTemplates.map(({ id: value, name: label }) => ({
          label,
          value,
        }));
        this.setState({
          mailTemplateLookup: options,
        });
      }
    });
  };

  submitSendMailForm = async (e) => {
    e.preventDefault();
    const today = new Date();
    today.setMinutes(today.getMinutes() + 30);
    const res = await createSendMailRequest({
      eventId: this.state.eventId,
      mailTemplateId: this.state.mailTemplateId,
      userId: 75,
      scheduledAt: moment(today).format(momentDateTimeFormat),
      externalEmailIds: this.state.externalEmailIds
        .split(",")
        .map((email) => email.trim()),
    });
    if (res) {
      const { message } = res;
      alert(message);
    }
    this.setState({
      showSendEmail: false,
      externalEmailIds: "",
      eventId: null,
      mailSubject: null,
      mailSchedule: null,
      mailTemplateId: null,
      mailTemplateLookup: [],
    });
  };

  render() {
    const { eventData, loading, globalFilter } = this.state;
    const tableValue =
      eventData &&
      Array.isArray(eventData) &&
      eventData.filter((row) => {
        const today = new Date(new Date().toDateString());
        const eventDate = row?.from_date ? new Date(row?.from_date) : today;
        if (this.state.menu === "Upcoming") {
          return eventDate >= today;
        } else {
          return eventDate < today;
        }
      });

    return (
      <div style={{ overflowX: "scroll" }}>
        <div className="app-main__outer container-fluid">
          <div className="back-event  m-3">
            <button onClick={() => this.props.history.goBack()}>
              <BsArrowLeftShort /> Events
            </button>
          </div>
          <div className="content">
            {/* Events Tool Bar */}
            <>
              <div className="d-xl-flex d-none mb-2 align-items-center justify-content-between">
                <div className="col-auto d-flex align-items-center">
                  <TableGlobalSearch
                    value={globalFilter}
                    onChange={(value) => this.setState({ globalFilter: value })}
                  />
                </div>
                <div className="col d-flex justify-content-end">
                  <div className="col-auto align-self-center">
                    <TableTabs
                      value={this.state.menu}
                      onChange={(menu) => this.setState({ menu })}
                      latestLabel="Upcoming"
                      latestValue="Upcoming"
                      archiveLabel="Archive"
                    />
                  </div>
                  {this.state.writeAccess && (
                    <>
                      <div className="d-none d-md-block col-auto create-event p-0 m-0 ml-2">
                        <Button
                          onClick={() =>
                            this.setState({ status: true, editEventId: "" })
                          }
                        >
                          Create Event
                        </Button>
                      </div>
                      <div className="d-none d-md-block col-auto create-event p-0 m-0 ml-2">
                        <Button
                          onClick={() =>
                            (window.location.pathname = "/event/mail-template")
                          }
                        >
                          Mail Templates
                        </Button>
                      </div>
                    </>
                  )}
                  <div className="col-auto btn-group p-0 m-0 ml-2">
                    <TableFilterDropdown
                      value={this.state.filter}
                      onChange={this.callApiFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="d-lg-flex d-none d-xl-none mb-2 align-items-center justify-content-between">
                <div className="col-auto">
                  <TableGlobalSearch
                    value={globalFilter}
                    onChange={(value) => this.setState({ globalFilter: value })}
                  />
                </div>
                <div className="col d-flex justify-content-end">
                  <div className="col-auto d-flex align-items-center p-0 m-0">
                    <TableTabs
                      value={this.state.menu}
                      onChange={(menu) => this.setState({ menu })}
                      latestLabel="Upcoming"
                      latestValue="Upcoming"
                    />
                  </div>
                  <div className="col-auto btn-group p-0 m-0 ml-2">
                    <TableFilterDropdown
                      value={this.state.filter}
                      onChange={this.callApiFilter}
                    />
                    {this.renderEventsMenu()}
                  </div>
                </div>
              </div>
              <div className="d-md-flex d-none d-lg-none mb-2 align-items-center justify-content-between">
                <div className="col-auto">
                  <TableGlobalSearch
                    value={globalFilter}
                    onChange={(value) => this.setState({ globalFilter: value })}
                  />
                </div>
                <div className="col d-flex flex-column">
                  <div className="col d-flex align-items-center justify-content-end p-0 m-0">
                    <TableTabs
                      value={this.state.menu}
                      onChange={(menu) => this.setState({ menu })}
                      latestLabel="Upcoming"
                      latestValue="Upcoming"
                    />
                  </div>
                  <div className="col d-flex justify-content-end mt-2">
                    <div className="col-auto btn-group p-0 m-0 ml-2">
                      <TableFilterDropdown
                        value={this.state.filter}
                        onChange={this.callApiFilter}
                      />
                      {this.renderEventsMenu()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-sm-flex d-md-none flex-wrap mb-2 align-items-center">
                <div className="col d-flex align-items-center p-0 m-0">
                  <TableGlobalSearch
                    value={globalFilter}
                    onChange={(value) => this.setState({ globalFilter: value })}
                  />
                </div>
                <div className="col-auto d-flex align-items-center justify-content-end p-0 m-0 mt-2">
                  <TableTabs
                    value={this.state.menu}
                    onChange={(menu) => this.setState({ menu })}
                    latestLabel="Upcoming"
                    latestValue="Upcoming"
                  />
                </div>
                <div className="col-auto btn-group user-button-grp d-flex align-items-center justify-content-end p-0 m-0 ml-auto">
                  <TableFilterDropdown
                    value={this.state.filter}
                    onChange={this.callApiFilter}
                  />
                  {this.renderEventsMenu()}
                </div>
              </div>
            </>
            <div className="table">
              <CommonTable
                value={tableValue}
                loading={loading}
                exportExcel={this.exportExcel}
                globalFilterFields={["id", "name", "description"]}
                globalFilter={globalFilter}
              >
                <Column
                  field="id"
                  header="SL"
                  sortable
                  // filter
                  filterPlaceholder="Search by SL"
                  style={{ minWidth: "3rem", maxWidth: "4rem" }}
                />
                <Column
                  field="name"
                  header="Event Name"
                  sortable
                  filter
                  filterPlaceholder="Search by Event Name"
                  style={{
                    minWidth: "20rem",
                    maxWidth: "23rem",
                  }}
                  body={(data) => (
                    <>
                      <Tooltip
                        target={`.event-title${data?.id}`}
                        content={data?.name}
                      />
                      <div
                        className={`event-title${data?.id}`}
                        data-toggle="tooltip"
                        title={data.name}
                      >
                        {data?.name && data?.name?.length > 36
                          ? data?.name.slice(0, 35) + "..."
                          : data?.name}
                      </div>
                    </>
                  )}
                />
                <Column
                  field="event_start_date"
                  header="Date"
                  sortable
                  filter
                  filterPlaceholder="Search by Date"
                  style={{
                    minWidth: "12rem",
                    maxWidth: "15rem",
                  }}
                />
                <Column
                  field="description"
                  header="Event Description"
                  sortable
                  filter
                  filterPlaceholder="Search by Event Description"
                  style={{
                    minWidth: "20rem",
                    maxWidth: "23rem",
                  }}
                  body={(data) => {
                    return (
                      <>
                        <Tooltip
                          target={`.event-description${data?.id}`}
                          content={data?.description}
                        />
                        <div
                          className={`event-description${data?.id}`}
                          data-toggle="tooltip"
                          title={data.description}
                        >
                          {data?.description && data?.description?.length > 35
                            ? data?.description.slice(0, 35) + "..."
                            : data?.description}
                        </div>
                      </>
                    );
                  }}
                />
                <Column
                  field="description"
                  header="Event Registrations"
                  sortable
                  filter
                  filterPlaceholder="Search by Event Registration Count"
                  align="center"
                  style={{
                    minWidth: "20rem",
                    maxWidth: "23rem",
                  }}
                  body={(data) => {
                    return (
                      <div>
                        <Button
                          label={`${(data?.registrations || []).length}`}
                          className="rounded-pill"
                          onClick={() => this.viewRegistrations(data)}
                          icon="pi pi-eye"
                          iconPos="right"
                        />
                      </div>
                    );
                  }}
                />
                <Column
                  header="Action"
                  align="center"
                  headerClassName="d-flex justify-content-center"
                  style={{ minWidth: "30rem", maxWidth: "33rem" }}
                  body={(data) => {
                    return (
                      <div className="d-flex align-items-center gap-1">
                        {this.state?.writeAccess &&
                          this.state?.menu === "Upcoming" && (
                            <div className="col-auto align-self-center px-0">
                              <Button
                                label="Send Mail"
                                className="rounded-pill"
                                // onClick={() => this.sendMail(data)}
                                onClick={() => this.openSendEmailDialog(data)}
                                disabled={
                                  String(data?.yet_to_be_decided) === "1"
                                }
                              />
                            </div>
                          )}
                        <div className="col-auto align-self-center px-0">
                          <Button
                            label="Speakers"
                            className="rounded-pill"
                            onClick={() => this.showSpeakers(data)}
                          />
                        </div>
                        <div className="col-auto align-self-center px-0">
                          <Button
                            label="Gallery"
                            className="rounded-pill"
                            onClick={() => this.showGallery(data)}
                          />
                        </div>
                        <div className="col-auto align-self-center px-0">
                          <MdEdit
                            size={40}
                            color="white"
                            onClick={() => this.updateEvent(data.id)}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#464eb8",
                              borderRadius: 20,
                              borderColor: "white",
                              borderWidth: 1,
                              padding: 8,
                            }}
                          />
                        </div>
                        {this.state?.writeAccess && (
                          <div className="col-auto align-self-center px-0">
                            <MdDelete
                              size={40}
                              color="white"
                              onClick={() => this.deleteEvent(data.id)}
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#464eb8",
                                borderRadius: 20,
                                borderColor: "white",
                                borderWidth: 1,
                                padding: 8,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              </CommonTable>
            </div>

            <EventsForm
              status={this.state.status}
              editEventId={this.state.editEventId}
              closeModel={() => this.setState({ status: false })}
              afterSubmit={this.tableFetchApi}
              saveAccess={this.state.writeAccess}
            />
            <ConfirmModal
              delete={true}
              visible={this.state.visible}
              heading="Delete"
              title="Are you sure you want to delete the Event?"
              confirm={() => this.getSuccess()}
              handleClose={() => this.setState({ visible: false })}
            />
            {this.renderGallery()}
            {this.renderSpeakers()}
          </div>
        </div>

        <div>
          <Modal size="md" centered show={this.state.showEventRegistration}>
            <Modal.Header>
              <div className="form-head w-100 d-flex jc-sb align-center">
                <div className="w-100 d-flex align-center">
                  <img src={logo} alt="logo" />
                  <h3 className="ml-2"> Event Registrations </h3>
                </div>
                <button
                  className="popup-button closeText d-flex"
                  onClick={() =>
                    this.setState({
                      showEventRegistration: false,
                      eventRegistrations: [],
                    })
                  }
                >
                  <span>
                    <AiOutlineCloseCircle />
                  </span>
                </button>
              </div>
            </Modal.Header>
            <div className="p-0">
              <Modal.Body>
                <div className="table">
                  <CommonTable
                    value={this.state.eventRegistrations || []}
                    exportExcel={this.exportExcelEventRegistrations}
                  >
                    <Column
                      key="name"
                      field="name"
                      header="Name"
                      sortable
                      filter
                      filterPlaceholder="Search by Name"
                      style={{
                        minWidth: "12rem",
                        maxWidth: "15rem",
                      }}
                    />
                    <Column
                      field="email"
                      header="Email"
                      sortable
                      filter
                      filterPlaceholder="Search by Email"
                      style={{
                        minWidth: "12rem",
                        maxWidth: "15rem",
                      }}
                    />
                    <Column
                      field="mobile_number"
                      header="Mobile Number"
                      sortable
                      filter
                      filterPlaceholder="Search by Mobile Number"
                      style={{
                        minWidth: "12rem",
                        maxWidth: "15rem",
                      }}
                    />
                    <Column
                      field="icai_membership_no"
                      header="ICAI Membership No"
                      sortable
                      filter
                      filterPlaceholder="Search by ICAI Membership No"
                      style={{
                        minWidth: "12rem",
                        maxWidth: "15rem",
                      }}
                    />
                    <Column
                      field="vcat_membership_status"
                      header="VCAT Membership Status"
                      sortable
                      filter
                      filterPlaceholder="Search by VCAT Membership Status"
                      style={{
                        minWidth: "12rem",
                        maxWidth: "15rem",
                      }}
                    />
                    <Column
                      field="message"
                      header="Message \ Query"
                      sortable
                      filter
                      filterPlaceholder="Search by Message \ Query"
                      style={{
                        minWidth: "12rem",
                        maxWidth: "15rem",
                      }}
                      body={(data) => {
                        return (
                          <>
                            <Tooltip
                              target={`.event-message${data?.id}`}
                              content={data?.message}
                            />
                            <div
                              className={`event-message${data?.id}`}
                              data-toggle="tooltip"
                              title={data.message}
                            >
                              {data?.message && data?.message?.length > 35
                                ? data?.message.slice(0, 35) + "..."
                                : data?.message}
                            </div>
                          </>
                        );
                      }}
                    />
                  </CommonTable>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div>
        <div>
          <Modal size="md" centered show={this.state.showSendEmail}>
            <Modal.Header>
              <div className="form-head w-100 d-flex jc-sb align-center">
                <div className="w-100 d-flex align-center">
                  <img src={logo} alt="logo" />
                  <h3 className="ml-2"> Event Mail Invitations </h3>
                </div>
                <button
                  className="popup-button closeText d-flex"
                  onClick={() =>
                    this.setState({
                      showSendEmail: false,
                      externalEmailIds: "",
                      eventId: null,
                      mailSubject: null,
                      mailSchedule: null,
                      mailTemplateId: null,
                      mailTemplateLookup: [],
                    })
                  }
                >
                  <span>
                    <AiOutlineCloseCircle />
                  </span>
                </button>
              </div>
            </Modal.Header>
            <div className="p-0">
              <Modal.Body>
                <form
                  className="align-items-center event-form p-3"
                  onSubmit={this.submitSendMailForm}
                >
                  <div className="row jc-sb mb-4">
                    <div className="input-row mb-3">
                      <div className="form-padding mb-3">
                        <span className="p-float-label">
                          <Dropdown
                            inputId="mailTemplateId"
                            value={this.state.mailTemplateId || ""}
                            options={this.state.mailTemplateLookup}
                            onChange={(e) =>
                              this.setState({ mailTemplateId: e.value })
                            }
                            optionLabel="label"
                            optionValue="value"
                            appendTo="self"
                            className="w-100"
                          />
                          <label htmlFor="mailTemplateId">
                            Mail Template <span className="asterisk">*</span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div className="input-row mb-3">
                      <div className="form-padding mb-3">
                        <span className="p-float-label">
                          <InputText
                            id="subject"
                            value={this.state.mailSubject || ""}
                            onChange={(e) =>
                              this.setState({ mailSubject: e.target.value })
                            }
                            className="w-100"
                          />
                          <label htmlFor="subject">Subject</label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="jc-sb mb-4">
                    <div className="input-row mb-3">
                      <div className="form-padding mb-3">
                        <span className="p-float-label">
                          <InputTextarea
                            id="externalEmailIds"
                            value={this.state.externalEmailIds}
                            onChange={(e) =>
                              this.setState({
                                externalEmailIds: e.target.value,
                              })
                            }
                            rows={3}
                            className="w-100"
                          />
                          <label htmlFor="externalEmailIds">
                            External Email Ids (Comma(,) separated)
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="cta-section">
                    <button
                      type="button"
                      className="btn  event-cta-trans"
                      onClick={() =>
                        this.setState({
                          showSendEmail: false,
                          externalEmailIds: "",
                          eventId: null,
                          mailSubject: null,
                          mailSchedule: null,
                          mailTemplateId: null,
                          mailTemplateLookup: [],
                        })
                      }
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn  event-cta">
                      Send Mail
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
export default observer(EventDashboard);
