import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

const ThreeDotsMenu = ({ items, className }) => {
  const menu = useRef(null);
  return (
    <>
      <Button
        icon="pi pi-ellipsis-v"
        className={`p-button-rounded p-button-text p-button-secondary align-self-center ${className}`}
        onClick={(e) => menu.current.toggle(e)}
      />
      <Menu model={items} popup ref={menu} />
    </>
  );
};

export default ThreeDotsMenu;
