import React from "react";
import AppConfig from "../modals/AppConfig";
import { observer } from "mobx-react-lite";
import { useLocation, Redirect, Route } from "react-router";

export const PublicRoute = observer((props) => {
  const currentPath = useLocation();
  const routes = ["/", "/login", "/forgotpassword", "/otp", "/createPassword"];
  if (AppConfig.api_key && routes.includes(currentPath.pathname)) {
    return <Redirect to="/dashboard" />;
  }

  return <Route {...props} />;
});

export const PrivateRoute = ({ component: PageComponent, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) =>
        AppConfig.api_key !== "" ? (
          <PageComponent {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};
