const global = {};
// Base url
global.baseUrl = "https://vcat.co.in/staging/vcat-api/api/v1/";
// global.baseUrl = "http://localhost:8000/api/v1/";

// Function url

global.apiUrls = {
  pages: "pages",
  banners: "banners",
  file: "files/upload",
  agenda: "files/upload",
  uploadDocument: "files/upload",
  delete_wing_member: "wing/members/delete",
};

export default global;
