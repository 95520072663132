import React from "react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import logo from "../../components/img/logo.png";
import AppConfig from "../../modals/AppConfig";
import {
  CheckDob,
  CheckUserName,
  DropDownCheck,
  CheckMeetingNumber,
} from "../../common/Validation";
import { uploadFile } from "../../common/uploadFile";
import { uploadAgenda } from "../../common/uploadAgenda";
import User from "../../modals/User";
import Notifications from "../../common/Notifications";
import { Chips } from "primereact/chips";
import { Checkbox } from "primereact/checkbox";
import {
  arrayLengthCheck,
  dateFormat,
  momentDateTimeFormFormat,
  onlyUniqueFilterFunction,
  strArrTojson,
} from "../../common/Common";
import { Calendar } from "primereact/calendar";
import EventSpeakerForm from "./EventSpeakerForm";
import CommonTextField from "../../components/CommonTextField";
import CommonMultiSelectField from "../../components/CommonMultiSelectField";
// Api
import {
  event,
  EventAutoPopulate,
  eventFormLookups,
  EventUpdate,
} from "../../libraries/event";

// Icons
import { IoAddCircleOutline } from "react-icons/io5";
import { BsTrashFill } from "react-icons/bs";
import { GrDocumentText, GrDocumentPdf } from "react-icons/gr";
import { BsUpload } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Image } from "primereact/image";

const DEFAULT_EVENT_IMAGE_URL =
  "https://vcat.co.in/staging/vcat-api/public/storage/document/1647946626.png";

class EventsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventName: "",
      personName: "",
      FromDate: "",
      ToDate: "",
      MeetingNumber: "",
      city: "",
      description: "",
      physical: "",
      virtual: "",
      venue: "",
      eventTag: [],
      topic: "",
      selectWing: [],
      selectMember: [],
      selectAgenda: "",
      meetingType: "",
      eventType: "",
      WingList: [],
      UserList: [],
      eventImage: DEFAULT_EVENT_IMAGE_URL,
      SelectedWing: [],
      SelectedMember: [],
      SelectedHost: [],
      agenda: "",
      hostname: "",
      type1: false,
      type2: false,
      speakers: [],
      yetToBeDecided: false,
      eventFor: null,
      eventForError: "",
      meetingTypeError: "",
      mailTemplateError: "",
      // new
      memberOptions: [],
      lookups: {},
      meetingTypeLookups: [],
      eventTypeLookups: [],
      eventForLookups: [],
      wingLookups: [],
      lifeMemberLookups: [],
      lifeTrusteeLookups: [],
      nonMemberLookups: [],
      otherMemberLookups: [],
      lifeMembers: [],
      lifeTrustees: [],
      nonMembers: [],
      otherMembers: [],
    };
    this.onMeetingTypeChange = this.onMeetingTypeChange.bind(this);
    this.onMailTemplateChange = this.onMailTemplateChange.bind(this);
    this.onEventTypeChange = this.onEventTypeChange.bind(this);
    this.onEventForChange = this.onEventForChange.bind(this);
  }

  componentDidMount() {
    this.loadLookups();
  }

  componentDidUpdate(prevProps) {
    const id = this.props.editEventId;
    if (this.props.status !== prevProps.status && id && this.props.status) {
      this.setState({ status: this.props.status });
      if (id) this.loadEventData(id);
    }
  }

  loadEventData = async (id) => {
    const response = await EventAutoPopulate(id);
    if (response) {
      const { status, result } = response;
      if (status === "success" && result) {
        const { events } = result;
        if (events) {
          const selectWing = [],
            lifeMembers = [],
            lifeTrustees = [],
            nonMembers = [],
            otherMembers = [];
          const { lookups } = this.state;
          const {
            wingLookups,
            lifeMemberLookups,
            lifeTrusteeLookups,
            nonMemberLookups,
            otherMemberLookups,
          } = lookups;
          if (wingLookups) {
            const wingMembers = strArrTojson(events?.wings);
            wingLookups?.forEach((member) => {
              if (arrayLengthCheck(wingMembers))
                if (wingMembers?.includes(member?.value))
                  selectWing.push(member);
            });
          }
          if (lifeMemberLookups) {
            const lifeMembersArr = strArrTojson(events?.life_members);
            lifeMemberLookups?.forEach((member) => {
              if (arrayLengthCheck(lifeMembersArr))
                if (lifeMembersArr?.includes(member?.value))
                  lifeMembers.push(member);
            });
          }
          if (lifeTrusteeLookups) {
            const lifeTrusteesArr = strArrTojson(events?.life_trustees);
            lifeTrusteeLookups?.forEach((member) => {
              if (arrayLengthCheck(lifeTrusteesArr))
                if (lifeTrusteesArr?.includes(member?.value))
                  lifeTrustees.push(member);
            });
          }
          if (nonMemberLookups) {
            const nonMembersArr = strArrTojson(events?.non_members);
            nonMemberLookups?.forEach((member) => {
              if (arrayLengthCheck(nonMembersArr))
                if (nonMembersArr?.includes(member?.value))
                  nonMembers.push(member);
            });
          }
          if (otherMemberLookups) {
            const otherMembersArr = strArrTojson(events?.other_members);
            otherMemberLookups?.forEach((member) => {
              if (arrayLengthCheck(otherMembersArr))
                if (otherMembersArr?.includes(member?.value))
                  otherMembers.push(member);
            });
          }
          this.setState({
            eventName: events?.name,
            hostname: events?.hosted_by,
            MeetingNumber: events?.code,
            description: events?.description,
            meetingType: events?.meeting_type,
            mailTemplate: events?.mail_template,
            eventType: events?.event_type,
            agenda: events?.agenda,
            eventImage: events?.image || DEFAULT_EVENT_IMAGE_URL,
            topic: events?.topic,
            venue: events?.venue,
            eventTag: events?.event_tags ? JSON.parse(events?.event_tags) : [],
            city: events?.city,
            selectWing,
            FromDate: events?.from_date
              ? moment(events?.from_date).format(momentDateTimeFormFormat)
              : events?.from_date,
            ToDate: events?.to_date
              ? moment(events?.to_date).format(momentDateTimeFormFormat)
              : events?.to_date,
            speakers: [],
            yetToBeDecided: String(events?.yet_to_be_decided) === "1",
            eventFor: events?.event_for,
            lifeMembers,
            lifeTrustees,
            nonMembers,
            otherMembers,
          });
          this.onSelectWing(selectWing);
        }
      }
    }
  };

  loadLookups = async () => {
    const response = await eventFormLookups();
    const { status, result: lookups } = response;
    if (status === "success" && lookups) {
      const {
        meetingTypeLookups,
        eventTypeLookups,
        eventForLookups,
        wingLookups,
      } = lookups;
      let lifeMemberLookups = arrayLengthCheck(lookups?.lifeMemberLookups)
          ? lookups?.lifeMemberLookups
          : [],
        lifeTrusteeLookups = arrayLengthCheck(lookups?.lifeTrusteeLookups)
          ? lookups?.lifeTrusteeLookups
          : [],
        nonMemberLookups = arrayLengthCheck(lookups?.nonMemberLookups)
          ? lookups?.nonMemberLookups
          : [],
        otherMemberLookups = arrayLengthCheck(lookups?.otherMemberLookups)
          ? lookups?.otherMemberLookups
          : [];

      if (arrayLengthCheck(wingLookups)) {
        wingLookups.forEach((wing) => {
          if (arrayLengthCheck(wing.lifeMemberLookups))
            lifeMemberLookups = [
              ...lifeMemberLookups,
              ...wing.lifeMemberLookups,
            ];
          if (arrayLengthCheck(wing.lifeTrusteeLookups))
            lifeTrusteeLookups = [
              ...lifeTrusteeLookups,
              ...wing.lifeTrusteeLookups,
            ];
          if (arrayLengthCheck(wing.nonMemberLookups))
            nonMemberLookups = [...nonMemberLookups, ...wing.nonMemberLookups];
          if (arrayLengthCheck(wing.otherMemberLookups))
            otherMemberLookups = [
              ...otherMemberLookups,
              ...wing.otherMemberLookups,
            ];
        });
      }
      lifeMemberLookups = lifeMemberLookups
        .map((member) => JSON.stringify(member))
        .filter(onlyUniqueFilterFunction)
        .map((member) => JSON.parse(member));
      lifeTrusteeLookups = lifeTrusteeLookups
        .map((member) => JSON.stringify(member))
        .filter(onlyUniqueFilterFunction)
        .map((member) => JSON.parse(member));
      nonMemberLookups = nonMemberLookups
        .map((member) => JSON.stringify(member))
        .filter(onlyUniqueFilterFunction)
        .map((member) => JSON.parse(member));
      otherMemberLookups = otherMemberLookups
        .map((member) => JSON.stringify(member))
        .filter(onlyUniqueFilterFunction)
        .map((member) => JSON.parse(member));
      this.setState({
        lookups,
        meetingTypeLookups,
        eventTypeLookups,
        eventForLookups,
        wingLookups,
        lifeMemberLookups,
        lifeTrusteeLookups,
        nonMemberLookups,
        otherMemberLookups,
      });
    }
    return lookups;
  };

  onMeetingTypeChange({ target: { value: meetingType } }) {
    this.setState({
      meetingType,
      meetingTypeError: "",
    });
  }

  onMailTemplateChange({ target: { value: mailTemplate } }) {
    this.setState({
      mailTemplate,
      mailTemplateError: "",
    });
  }

  onEventTypeChange({ target: { value: eventType } }) {
    this.setState({
      eventType,
      eventTypeError: "",
    });
  }

  onEventForChange({ target: { value: eventFor } }) {
    this.setState({
      eventFor,
      eventForError: "",
    });
  }

  onSelectWing = (selectWing) => {
    const { lookups } = this.state;
    let lifeMemberLookups = arrayLengthCheck(lookups?.lifeMemberLookups)
        ? lookups?.lifeMemberLookups
        : [],
      lifeTrusteeLookups = arrayLengthCheck(lookups?.lifeTrusteeLookups)
        ? lookups?.lifeTrusteeLookups
        : [],
      nonMemberLookups = arrayLengthCheck(lookups?.nonMemberLookups)
        ? lookups?.nonMemberLookups
        : [],
      otherMemberLookups = arrayLengthCheck(lookups?.otherMemberLookups)
        ? lookups?.otherMemberLookups
        : [];
    let wingLookups = [];
    if (arrayLengthCheck(selectWing)) {
      const selectWingValues = selectWing.map(({ value }) => value);
      wingLookups = this.state.wingLookups.filter(
        ({ value }) => !selectWingValues.includes(value)
      );
    } else {
      wingLookups = this.state.wingLookups;
    }
    if (arrayLengthCheck(wingLookups)) {
      wingLookups.forEach((wing) => {
        if (arrayLengthCheck(wing.lifeMemberLookups))
          lifeMemberLookups = [...lifeMemberLookups, ...wing.lifeMemberLookups];
        if (arrayLengthCheck(wing.lifeTrusteeLookups))
          lifeTrusteeLookups = [
            ...lifeTrusteeLookups,
            ...wing.lifeTrusteeLookups,
          ];
        if (arrayLengthCheck(wing.nonMemberLookups))
          nonMemberLookups = [...nonMemberLookups, ...wing.nonMemberLookups];
        if (arrayLengthCheck(wing.otherMemberLookups))
          otherMemberLookups = [
            ...otherMemberLookups,
            ...wing.otherMemberLookups,
          ];
      });
    }
    const lifeMembers = this.state.lifeMembers.filter((lifeMember) =>
      lifeMemberLookups.includes(lifeMember)
    );
    const lifeTrustees = this.state.lifeTrustees.filter((lifeTrustee) =>
      lifeTrusteeLookups.includes(lifeTrustee)
    );
    const nonMembers = this.state.nonMembers.filter((nonMember) =>
      nonMemberLookups.includes(nonMember)
    );
    const otherMembers = this.state.otherMembers.filter((otherMember) =>
      otherMemberLookups.includes(otherMember)
    );
    lifeMemberLookups = lifeMemberLookups
      .map((member) => JSON.stringify(member))
      .filter(onlyUniqueFilterFunction)
      .map((member) => JSON.parse(member));
    lifeTrusteeLookups = lifeTrusteeLookups
      .map((member) => JSON.stringify(member))
      .filter(onlyUniqueFilterFunction)
      .map((member) => JSON.parse(member));
    nonMemberLookups = nonMemberLookups
      .map((member) => JSON.stringify(member))
      .filter(onlyUniqueFilterFunction)
      .map((member) => JSON.parse(member));
    otherMemberLookups = otherMemberLookups
      .map((member) => JSON.stringify(member))
      .filter(onlyUniqueFilterFunction)
      .map((member) => JSON.parse(member));
    this.setState({
      selectWing,
      lifeMembers,
      lifeTrustees,
      nonMembers,
      otherMembers,
      lifeMemberLookups,
      lifeTrusteeLookups,
      nonMemberLookups,
      otherMemberLookups,
    });
  };

  onSelectMember = (e) => {
    this.setState({
      selectMember: e,
    });
  };

  renderThumbnailImage = () => {
    if (this.state.eventImage)
      return (
        <div
          className="d-flex px-5 align-items-center"
          style={{ minHeight: "50px" }}
        >
          <Image
            src={this.state.eventImage}
            alt="EVENT-BANNER"
            imageStyle={{
              height: "auto",
              maxHeight: "250px",
              width: "auto",
              maxWidth: "100%",
              border: "none",
              borderRadius: "0",
            }}
            preview
          />
          <div className="col-2 align-self-center ml-auto">
            <BsTrashFill
              className="theme-font-color pointer"
              onClick={() => this.setState({ eventImage: "" })}
            />
          </div>
        </div>
      );
    return <></>;
  };

  renderFileType() {
    const { agenda } = this.state;
    return agenda.split(".").pop() === "pdf" ? (
      <div className="col-md-10 ">
        <div className="thumbnail-image">
          <GrDocumentPdf
            style={{ width: "50px", height: "50px", cursor: "pointer" }}
          />
        </div>
      </div>
    ) : (
      <div className="col-md-10 ">
        <div className="thumbnail-image">
          <GrDocumentText
            style={{ width: "50px", height: "50px", cursor: "pointer" }}
          />
        </div>
      </div>
    );
  }

  renderThumbnailFile = () => {
    return this.state.agenda ? (
      <div
        className="d-flex px-5 align-items-center"
        style={{ minHeight: "60px" }}
      >
        <div className="col-auto thumbnail-image">{this.renderFileType()}</div>
        <div className="col-2 align-self-center ml-auto">
          <BsTrashFill
            className="theme-font-color pointer"
            onClick={() => this.setState({ agenda: "" })}
          />
        </div>
      </div>
    ) : null;
  };

  handleClose = () => {
    Notifications.setDocType("");
    Notifications.setDocTypeError(0);
    Notifications.setMediaSizeImg("");
    Notifications.setMediaSizeDoc("");
    this.setState(
      {
        personName: "",
        FromDate: "",
        ToDate: "",
        eventName: "",
        // selectHost: [],
        MeetingNumber: "",
        city: "",
        description: "",
        meetingType: "",
        mailTemplate: "",
        meetingTypeError: "",
        mailTemplateError: "",
        eventType: "",
        eventTypeError: "",
        eventFor: null,
        eventForError: "",
        venue: "",
        eventTag: [],
        topic: "",
        hostname: "",
        selectWing: [],
        selectMember: [],
        agenda: "",
        eventImage: DEFAULT_EVENT_IMAGE_URL,
        eventNameError: "",
        hostnameError: "",
        FromDateError: "",
        ToDateError: "",
        meetingNumberError: "",
        cityError: "",
        descriptionError: "",
        venueError: "",
        eventTagError: "",
        SelectWingError: "",
        SelectMemberError: "",
        imageError: "",
        speakers: [],

        yetToBeDecided: false,
        // new
        memberOptions: [],
        lookups: {},
        meetingTypeLookups: [],
        eventTypeLookups: [],
        eventForLookups: [],
        wingLookups: [],
        lifeMemberLookups: [],
        lifeTrusteeLookups: [],
        nonMemberLookups: [],
        otherMemberLookups: [],
        lifeMembers: [],
        lifeTrustees: [],
        nonMembers: [],
        otherMembers: [],
      },
      () => {
        this.loadLookups();
        this.props.closeModel(false);
      }
    );
  };

  validateTopic = () => {
    const topicError = CheckUserName(this.state.topic);
    if (topicError === 1) {
      this.setState({ topicError: "Field empty" });
      return false;
    } else return true;
  };

  validateEventName = () => {
    const eventNameError = CheckUserName(this.state.eventName);
    if (eventNameError === 1) {
      this.setState({ eventNameError: "Field empty" });
      return false;
    } else return true;
  };

  validateSelectHost = () => {
    const check = this.state.hostname?.length === 0;
    let hostnameError = "Field empty";
    if (!check) hostnameError = "";
    this.setState({ hostnameError });
    return !check;
  };

  validateFromDateError = () => {
    const FromDateError = CheckDob(this.state.FromDate);
    if (FromDateError === 1) {
      this.setState({ FromDateError: "Field empty" });
      return false;
    } else return true;
  };

  validateToDateError = () => {
    const ToDateError = CheckDob(this.state.ToDate);
    if (ToDateError === 1) {
      this.setState({ ToDateError: "Field empty" });
      return false;
    } else return true;
  };

  validateMeetingNumber = () => {
    const meetingNumberError = CheckMeetingNumber(this.state.MeetingNumber);
    if (meetingNumberError === 1) {
      this.setState({ meetingNumberError: "Field empty" });
      return false;
    }
    return true;
  };

  validateCity = () => {
    const cityError = CheckUserName(this.state.city);
    if (cityError === 1) {
      this.setState({ cityError: "Field empty" });
      return false;
    } else return true;
  };

  validateDescription = () => {
    const descriptionError = CheckUserName(this.state.description);
    if (descriptionError === 1) {
      this.setState({ descriptionError: "Field empty" });
      return false;
    } else return true;
  };

  validateImage = () => {
    const imageError = CheckUserName(this.state.eventImage);
    if (imageError === 1) {
      this.setState({ imageError: "Image required" });
      return false;
    } else return true;
  };

  validateVenue = () => {
    const venueError = CheckUserName(this.state.venue);
    if (venueError === 1) {
      this.setState({ venueError: "Field empty" });
      return false;
    } else return true;
  };

  validateEventTag = () => {
    const eventTagError =
      this.state?.eventTag &&
      Array.isArray(this.state?.eventTag) &&
      this.state?.eventTag.length > 0
        ? null
        : 1;
    if (eventTagError === 1) {
      this.setState({ eventTagError: "Field empty" });
      return false;
    } else return true;
  };

  validateSelectWing = () => {
    const SelectWingError = DropDownCheck(this.state.selectWing);
    if (SelectWingError === 1) {
      this.setState({ SelectWingError: "Field empty" });
      return false;
    } else return true;
  };

  validateSelectMember = () => {
    const SelectMemberError = DropDownCheck(this.state.selectMember);
    if (SelectMemberError === 1) {
      this.setState({ SelectMemberError: "Field empty" });
      return false;
    } else return true;
  };

  ValidateAll = () => {
    if (this.state?.yetToBeDecided) {
      const result = this.validateEventName();
      if (!result) AppConfig.showValidationError();
      return result;
    }
    const meetingTypeInput = this.checkMeetingType();
    const eventTypeInput = this.checkEventType();
    const eventNameInput = this.validateEventName();
    const selectHostInput = this.validateSelectHost();
    const FromDateInput = this.validateFromDateError();
    const ToDateInput = this.validateToDateError();
    const MeetingNumberInput = this.validateMeetingNumber();
    const CityInput = this.validateCity();
    const descriptionInput = this.validateDescription();
    const venueInput = this.validateVenue();
    const eventTagInput = this.validateEventTag();
    const eventForInput = this.checkEventFor();

    const result =
      meetingTypeInput &&
      eventTypeInput &&
      eventNameInput &&
      FromDateInput &&
      ToDateInput &&
      MeetingNumberInput &&
      descriptionInput &&
      CityInput &&
      venueInput &&
      selectHostInput &&
      eventTagInput &&
      eventForInput;
    if (!result) AppConfig.showValidationError();
    return result;
  };

  checkMeetingType() {
    if (!this.state.meetingType)
      this.setState({ meetingTypeError: "Please select Meeting type" });
    return !!this.state.meetingType;
  }

  checkEventType() {
    if (!this.state.eventType)
      this.setState({ eventTypeError: "Please select Event type" });
    return !!this.state.eventType;
  }

  checkEventFor() {
    if (!this.state.eventFor)
      this.setState({ eventForError: "Please select Event for" });
    return !!this.state.eventFor;
  }

  // on submit sign in function
  onSubmitCreate = async (e) => {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }

    const editId = this.props.editEventId;
    e.preventDefault();
    const allValidation = this.ValidateAll();
    if (allValidation) {
      const {
        selectWing,
        selectMember,
        hostname,
        lifeMembers,
        lifeTrustees,
        nonMembers,
        otherMembers,
      } = this.state;
      const selectedWings = [];
      for (let i in selectWing) {
        selectedWings.push(selectWing[i]["value"]);
      }
      const selectedMembers = [];
      for (let i in selectMember) {
        selectedMembers.push(selectMember[i]["value"]);
      }
      const selectedLifeMembers = [];
      for (let i in lifeMembers) {
        selectedLifeMembers.push(lifeMembers[i]["value"]);
      }
      const selectedLifeTrustees = [];
      for (let i in lifeTrustees) {
        selectedLifeTrustees.push(lifeTrustees[i]["value"]);
      }
      const selectedNonMembers = [];
      for (let i in nonMembers) {
        selectedNonMembers.push(nonMembers[i]["value"]);
      }
      const selectedOtherMembers = [];
      for (let i in otherMembers) {
        selectedOtherMembers.push(otherMembers[i]["value"]);
      }
      const requestData = {
        name: this.state.eventName,
        hosted_by: hostname,
        from_date: this.state?.FromDate
          ? moment(this.state?.FromDate).format(momentDateTimeFormFormat)
          : null,
        to_date: this.state?.ToDate
          ? moment(this.state?.ToDate).format(momentDateTimeFormFormat)
          : null,
        code: this.state?.MeetingNumber ? this.state?.MeetingNumber : null,
        city: this.state?.city ? this.state?.city : null,
        description: this.state?.description ? this.state?.description : null,
        meeting_type: this.state?.meetingType ? this.state?.meetingType : null,
        mail_template: this.state?.mailTemplate
          ? this.state?.mailTemplate
          : null,
        venue: this.state?.venue ? this.state?.venue : null,
        event_tags: this.state?.eventTag ? this.state?.eventTag : null,
        wings: selectedWings,
        members: selectedMembers,
        event_type: this.state?.eventType ? this.state?.eventType : null,
        filePath: this.state?.agenda ? this.state?.agenda : null,
        topic: this.state?.topic ? this.state?.topic : null,
        image:
          this.state.eventImage !== undefined &&
          this.state.eventImage !== null &&
          this.state.eventImage.length > 0
            ? this.state.eventImage
            : DEFAULT_EVENT_IMAGE_URL,
        user_id: User.user_id,
        agenda: this.state?.agenda ? this.state?.agenda : null,
        speakers: this.state.speakers
          ? JSON.stringify(this.state.speakers)
          : "[]",
        yet_to_be_decided: !!this.state.yetToBeDecided,
        event_for: this.state?.eventFor ? this.state?.eventFor : null,
        life_members: selectedLifeMembers,
        life_trustees: selectedLifeTrustees,
        non_members: selectedNonMembers,
        other_members: selectedOtherMembers,
      };
      if (editId) {
        requestData["user_id"] = User.user_id;
        const id = this.props.editEventId;
        const response = await EventUpdate(requestData, id);
        if (response && response.status === "success") {
          this.handleClose();
          AppConfig.setMessage("Event updated successfully", false);
        } else if (response.status === "error") {
          this.handleClose();
          AppConfig.setMessage(response?.result);
        }
      } else {
        const response = await event(requestData);
        if (response && response.status === "success") {
          this.handleClose();
          User.setRefresh(true);
          AppConfig.setMessage("Event Created successfully", false);
        } else if (response.status === "error" || response.status === "0") {
          let message = "";
          if ("result" in response) {
            message = response.result.error[0];
          } else {
            message = Object.keys(response.error)[0];
          }
          AppConfig.setMessage(message);
        }
      }
      if (this.props?.afterSubmit) await this.props.afterSubmit();
    }
    return false;
  };

  selectUploadAgendaFile = (e) => {
    e.preventDefault();
    const agenda = e.target.files[0];
    const mediaSize = e.target.files[0].size;
    Notifications.setMediaSizeDoc(mediaSize);
    uploadAgenda(agenda, this.callBackEventAgenda);
  };

  callBackEventAgenda = (response = false) => {
    if (response && response.status === "success") {
      this.setState({ agenda: response.result.url });
    }
  };

  handleUploadAgendaFile = (e) => {
    e.preventDefault();
    const fileSelectorAgenda = document.getElementById("uploadAgendaFile");
    fileSelectorAgenda.click();
  };

  handleUploadImage = (e) => {
    e.preventDefault();
    const fileSelector = document.getElementById("eventImage");
    fileSelector.click();
  };

  selectUploadImage = (e) => {
    e.preventDefault();
    const event_image = e.target.files[0];
    const mediaSize = e.target.files[0].size;
    Notifications.setMediaSizeImg(mediaSize);
    uploadFile(event_image, this.callBackEventImage);
  };

  callBackEventImage = (response = false) => {
    if (response && response.status === "success") {
      this.setState({ eventImage: response.result.url });
    }
  };

  onFocusEventHandler = (propName) => () => {
    this.setState({ [propName]: "" });
  };

  labelStyle = (error) => (error ? { color: "#d92550" } : null);

  toggleYetToBeDecided = (e) =>
    this.setState({
      yetToBeDecided: e.checked,
      eventNameError: "",
      hostnameError: "",
      FromDateError: "",
      ToDateError: "",
      meetingNumberError: "",
      cityError: "",
      descriptionError: "",
      venueError: "",
      eventTagError: "",
      SelectWingError: "",
      SelectMemberError: "",
      imageError: "",
      meetingTypeError: "",
      mailTemplateError: "",
      eventTypeError: "",
      eventForError: "",
    });

  render() {
    let requiredMark = <span className="asterisk">*</span>;
    if (this.state?.yetToBeDecided) requiredMark = null;
    const fromMinDate = new Date();
    fromMinDate.setDate(fromMinDate.getDate() - 7);
    return (
      <div>
        <Modal
          size="md"
          className="border-style rounded"
          centered
          show={this.props.status}
        >
          <Modal.Header>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <span className="col-auto text-white h4 p-0">
                <img src={logo} alt="logo" />
                &nbsp;&nbsp;{this.props.editEventId ? "Update" : "Create"} Event
              </span>

              <button
                className="col-auto popup-button"
                onClick={this.handleClose}
              >
                <AiOutlineCloseCircle />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <form
              className="d-flex flex-wrap"
              onSubmit={this.onSubmitCreate}
              style={{ rowGap: "1rem" }}
            >
              <CommonTextField
                id="eventName"
                className="col-6"
                label="Event Name"
                placeholder="Enter the Event Name"
                value={this.state.eventName}
                error={this.state?.eventNameError}
                onChange={(eventName) =>
                  this.setState({
                    eventName,
                    eventNameError: "",
                  })
                }
                onFocus={this.onFocusEventHandler("eventNameError")}
                required
              />
              <span className="col-6 align-self-center d-flex">
                <Checkbox
                  inputId="yetToBeDecided"
                  checked={this.state?.yetToBeDecided}
                  onChange={this.toggleYetToBeDecided}
                />
                <div>
                  <label htmlFor="yetToBeDecided">
                    &nbsp;&nbsp;Yet to be decided
                  </label>
                </div>
              </span>
              <CommonTextField
                id="hostName"
                className="col-12"
                label="Hosted by"
                placeholder="Enter Hosted by"
                value={this.state.hostname}
                error={this.state?.hostnameError}
                onChange={(hostname) =>
                  this.setState({
                    hostname,
                    hostnameError: "",
                  })
                }
                onFocus={this.onFocusEventHandler("hostnameError")}
                required={!this.state?.yetToBeDecided}
              />
              <div className="col-6">
                <label
                  className="selectIcon d-flex align-center"
                  htmlFor="fromDate"
                >
                  From {requiredMark}
                </label>
                <Calendar
                  showIcon
                  autoComplete="off"
                  autoSave="off"
                  inputClassName={`form-control ${
                    this.state.FromDateError ? "validationError" : ""
                  }`}
                  placeholder={
                    this.state?.FromDateError || "dd-mm-yyyy --:-- --"
                  }
                  className="w-100"
                  value={
                    this.state?.FromDate ? new Date(this.state?.FromDate) : null
                  }
                  onChange={({ value }) =>
                    this.setState({ FromDate: value, FromDateError: "" })
                  }
                  onFocus={this.onFocusEventHandler("FromDateError")}
                  maxDate={
                    this.state?.ToDate ? new Date(this.state?.ToDate) : null
                  }
                  appendTo="self"
                  showTime
                  hourFormat="12"
                  dateFormat={dateFormat}
                  readOnlyInput
                />
              </div>
              <div className="col-6">
                <label
                  className="selectIcon d-flex align-center"
                  htmlFor="toDate"
                >
                  To {requiredMark}
                </label>
                <Calendar
                  showIcon
                  autoComplete="off"
                  autoSave="off"
                  inputClassName={`form-control ${
                    this.state.ToDateError ? "validationError " : ""
                  }`}
                  placeholder={this.state?.ToDateError || "dd-mm-yyyy --:-- --"}
                  className="w-100"
                  value={
                    this.state?.ToDate ? new Date(this.state?.ToDate) : null
                  }
                  onChange={({ value }) =>
                    this.setState({ ToDate: value, ToDateError: "" })
                  }
                  onFocus={this.onFocusEventHandler("ToDateError")}
                  minDate={
                    this.state?.FromDate ? new Date(this.state?.FromDate) : null
                  }
                  appendTo="self"
                  showTime
                  hourFormat="12"
                  dateFormat={dateFormat}
                  readOnlyInput
                />
              </div>
              <CommonTextField
                id="meetingNumber"
                className="col-6"
                label="Meeting Number"
                placeholder="e.g(202120)"
                value={this.state.MeetingNumber}
                error={this.state?.meetingNumberError}
                onChange={(MeetingNumber) =>
                  this.setState({
                    MeetingNumber,
                    meetingNumberError: "",
                  })
                }
                onFocus={this.onFocusEventHandler("meetingNumberError")}
                required={!this.state?.yetToBeDecided}
                type="tel"
                maxLength="9"
              />
              <CommonTextField
                id="city"
                className="col-6"
                label="City"
                placeholder="City"
                value={this.state.city}
                error={this.state?.cityError}
                onChange={(city) =>
                  this.setState({
                    city,
                    cityError: "",
                  })
                }
                onFocus={this.onFocusEventHandler("cityError")}
                required={!this.state?.yetToBeDecided}
                maxLength="32"
              />
              <div className="col-sm-6 col-12">
                <label>Event Description {requiredMark}</label>
                <textarea
                  className={`form-control text-box mb-3 ${
                    this.state?.descriptionError && "validationError"
                  }`}
                  id="eventDescription"
                  label="Event Description"
                  placeholder={
                    this.state?.descriptionError || "Event Description"
                  }
                  type="textarea"
                  onFocus={this.onFocusEventHandler("descriptionError")}
                  style={{ height: "100px" }}
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                />
              </div>
              <div
                className="col-sm-6 col-12 d-flex flex-column"
                style={{ rowGap: "1rem" }}
              >
                <div>
                  <label htmlFor="meetingTypeRadio">
                    Select Meeting Type {requiredMark}
                  </label>
                  <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                    {this.state.meetingTypeLookups.map((meetingTypeOption) => (
                      <div key={`meetingTypeRadio-${meetingTypeOption.value}`}>
                        <input
                          id={`meetingTypeRadio-${meetingTypeOption.value}`}
                          name="meetingType"
                          type="radio"
                          value={meetingTypeOption.value}
                          checked={
                            this.state.meetingType === meetingTypeOption.value
                          }
                          onChange={this.onMeetingTypeChange}
                        />
                        <label
                          htmlFor={`meetingTypeRadio-${meetingTypeOption.value}`}
                          style={this.labelStyle(this.state?.meetingTypeError)}
                        >
                          &nbsp;&nbsp;{meetingTypeOption.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label htmlFor="eventTypeRadio">
                    Select Event Type {requiredMark}
                  </label>
                  <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                    {this.state.eventTypeLookups.map((eventTypeOption) => (
                      <div key={`eventTypeRadio-${eventTypeOption.value}`}>
                        <input
                          id={`eventTypeRadio-${eventTypeOption.value}`}
                          name="eventType"
                          type="radio"
                          value={eventTypeOption.value}
                          checked={
                            this.state.eventType === eventTypeOption.value
                          }
                          onChange={this.onEventTypeChange}
                        />
                        <label
                          htmlFor={`eventTypeRadio-${eventTypeOption.value}`}
                          style={this.labelStyle(this.state?.eventTypeError)}
                        >
                          &nbsp;&nbsp;{eventTypeOption.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <CommonTextField
                id="venue"
                className="col-sm-6 col-12"
                label="Venue"
                placeholder="Enter Location/ Meeting Link"
                value={this.state.venue}
                error={this.state?.venueError}
                onChange={(venue) =>
                  this.setState({
                    venue,
                    venueError: "",
                  })
                }
                onFocus={this.onFocusEventHandler("venueError")}
                required={!this.state?.yetToBeDecided}
              />
              <CommonTextField
                id="topic"
                className="col-sm-6 col-12"
                label="Topic"
                placeholder="Topic"
                value={this.state.topic}
                onChange={(topic) => this.setState({ topic })}
              />
              <div className="col-sm-6 col-12">
                <label>Event Tags {requiredMark}</label>
                <Chips
                  className={`w-100 rounded-3 ${
                    this.state?.eventTagError && "validationError"
                  }`}
                  value={this.state.eventTag}
                  onChange={({ value: eventTag }) =>
                    this.setState({ eventTag })
                  }
                  separator=","
                  placeholder={
                    this.state?.eventTagError ||
                    "Type and press enter to add more"
                  }
                  onFocus={this.onFocusEventHandler("eventTagError")}
                />
              </div>
              <div className="col-sm-6 col-12">
                <label htmlFor="eventForTypeRadio">
                  Select Event For {requiredMark}
                </label>
                <div className="d-flex flex-wrap" style={{ gap: "1rem" }}>
                  {this.state.eventForLookups.map((eventForTypeOption) => (
                    <div key={`eventForTypeRadio-${eventForTypeOption.value}`}>
                      <input
                        id={`eventForTypeRadio-${eventForTypeOption.value}`}
                        name="meetingType"
                        type="radio"
                        value={eventForTypeOption.value}
                        checked={
                          this.state.meetingType === eventForTypeOption.value
                        }
                        onChange={this.onEventForChange}
                      />
                      <label
                        htmlFor={`eventForTypeRadio-${eventForTypeOption.value}`}
                        style={this.labelStyle(this.state?.eventForError)}
                      >
                        &nbsp;&nbsp;{eventForTypeOption.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              {this.props?.editEventId !== undefined &&
                this.props?.editEventId !== null &&
                !this.props?.editEventId && (
                  <div className="col-12 upload-section rounded-1rem d-flex flex-column px-0">
                    <span className="p-4 h4 bg-theme text-center text-sm-right rounded-1rem mb-4">
                      Add Speaker&nbsp;&nbsp;
                      <IoAddCircleOutline />
                    </span>
                    <EventSpeakerForm
                      speakers={this.state.speakers}
                      setSpeakers={(speakers) => this.setState({ speakers })}
                    />
                  </div>
                )}

              <div
                className="col-12 upload-section rounded-1rem d-flex flex-column px-0"
                style={{ rowGap: "1rem" }}
              >
                <span className="p-4 h4 bg-theme text-center text-sm-right rounded-1rem mb-4">
                  Add Member&nbsp;&nbsp;
                  <IoAddCircleOutline />
                </span>
                <CommonMultiSelectField
                  className="px-3"
                  label="Wings"
                  placeholder="Select the Wings"
                  value={this.state.selectWing}
                  onChange={this.onSelectWing}
                  options={this.state.wingLookups}
                />
                <CommonMultiSelectField
                  className="px-3"
                  label="Life Members"
                  placeholder="Select the Life Members"
                  value={this.state.lifeMembers}
                  onChange={(lifeMembers) => this.setState({ lifeMembers })}
                  options={this.state.lifeMemberLookups}
                />
                <CommonMultiSelectField
                  className="px-3"
                  label="Life Trustee"
                  placeholder="Select the Life Trustee"
                  value={this.state.lifeTrustees}
                  onChange={(lifeTrustees) => this.setState({ lifeTrustees })}
                  options={this.state.lifeTrusteeLookups}
                />
                <CommonMultiSelectField
                  className="px-3"
                  label="Non Members"
                  placeholder="Select the Non Members"
                  value={this.state.nonMembers}
                  onChange={(nonMembers) => this.setState({ nonMembers })}
                  options={this.state.nonMemberLookups}
                />
                <CommonMultiSelectField
                  className="px-3"
                  label="Members"
                  placeholder="Select the Members"
                  value={this.state.otherMembers}
                  onChange={(otherMembers) => this.setState({ otherMembers })}
                  options={this.state.otherMemberLookups}
                />
              </div>

              <div className="col-12 p-0">
                {this.state.imageError ? (
                  <div
                    className="form-padding upload-agenda validationError"
                    onFocus={this.onFocusEventHandler("imageError")}
                  >
                    <div onFocus={this.onFocusEventHandler("imageError")}>
                      <button
                        className="btn small-font-size font-style"
                        onClick={this.handleUploadImage}
                        onFocus={this.onFocusEventHandler("imageError")}
                      >
                        <span
                          className="mx-3 red-text"
                          style={{ color: "red" }}
                        >
                          Image required
                        </span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="upload-agenda">
                    {this.state.eventImage ? (
                      this.renderThumbnailImage()
                    ) : (
                      <>
                        <input
                          id="eventImage"
                          type="file"
                          accept="image/*"
                          onFocus={this.onFocusEventHandler("imageError")}
                          onChange={this.selectUploadImage}
                          hidden
                        />
                        <button
                          className="btn"
                          onClick={this.handleUploadImage}
                        >
                          <BsUpload />
                          <span>Upload Event Image</span>
                          <br />
                          <span>Image resolution should be less than 5mb</span>
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>

              <div className="col-12 p-0 upload-agenda">
                {this.state.agenda ? (
                  this.renderThumbnailFile()
                ) : (
                  <>
                    <input
                      id="uploadAgendaFile"
                      type="file"
                      accept=".doc,.docx,.pdf"
                      value={this.state.selectAgenda}
                      onChange={this.selectUploadAgendaFile}
                      hidden
                    />
                    <button
                      className="btn"
                      onClick={this.handleUploadAgendaFile}
                    >
                      <BsUpload />
                      <span>Upload Agenda </span>
                      <br />
                      <span>
                        The file must be a file of type: doc, docx, pdf
                      </span>
                    </button>
                  </>
                )}
              </div>

              {this.props?.saveAccess && (
                <div className="col-12 p-0 d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn event-cta-trans"
                    onClick={this.handleClose}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn event-cta">
                    {this.props.editEventId ? "Update Event" : "Create Event"}
                  </button>
                </div>
              )}
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default EventsForm;
