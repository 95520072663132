import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const CommonMultiSelectField = ({
  id,
  label,
  required = false,
  error = "",
  className,
  placeholder,
  value,
  onChange,
  options = [],
}) => {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    setToggle((oldValue) => !oldValue);
  }, [options]);
  return (
    <div className={className}>
      <label className="d-flex align-center" htmlFor={id}>
        <span>
          {label}&nbsp;{required && <span className="asterisk">*</span>}
        </span>
      </label>
      {toggle ? (
        <MultiSelect
          labelledBy={error || placeholder}
          type="drop"
          className={error && "validationError"}
          placeholder={error}
          value={value}
          onChange={onChange}
          options={options}
        />
      ) : (
        <MultiSelect
          labelledBy={error || placeholder}
          type="drop"
          className={error && "validationError"}
          placeholder={error}
          value={value}
          onChange={onChange}
          options={options}
        />
      )}
    </div>
  );
};

export default CommonMultiSelectField;
