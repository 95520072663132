export const Permissions = [
    { name: 'READ_ACCOUNT' },
    { name: 'WRITE_ACCOUNT' },
    { name: 'READ_MOM' },
    { name: 'WRITE_MOM' },
]

export const ApiPermissions = [
    { name: 'READ_ACCOUNT' },
    { name: 'WRITE_ACCOUNT' },
    { name: 'READ_MOM' },
    { name: 'WRITE_MOM' },
]