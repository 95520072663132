import "../libraries/Global";
import { uploadApi } from "../libraries/uploadApi";

export const UploadImage = async (file, callBack) => {
  // const id = User.user_id;
  const functionUrl = "files/upload";
  console.log("function Url : ", functionUrl);
  try {
    const response = await uploadApi(
      functionUrl,
      file,
      callBack,
      "image",
      "image",
      "image"
    );
    if (response) {
      return callBack(response);
    }
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
export const UploadDoc = async (file, callBack) => {
  // const id = User.user_id;
  const functionUrl = "files/upload";
  console.log("function Url : ", functionUrl);
  try {
    const response = await uploadApi(
      functionUrl,
      file,
      callBack,
      "pdf/txt/csv/doc/docx",
      "document_path",
      "document_name"
    );
    if (response) {
      return callBack(response);
    }
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};

export const UploadResDoc = async (file, callBack) => {
  // const id = User.user_id;
  const functionUrl = "files/upload";

  console.log("function Url : ", functionUrl);
  try {
    const response = await uploadApi(
      functionUrl,
      file,
      callBack,
      "resource_docs",
      "document_path",
      "document_name"
    );
    if (response) {
      return callBack(response);
    }
  } catch (error) {
    console.log("Error from catch => ", error);
  }
};
