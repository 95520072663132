import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import {
  doc,
  collection,
  setDoc,
  query,
  getDocs,
  where,
  updateDoc,
} from "firebase/firestore";
import User from "./modals/User";
import moment from "moment";

const firebaseApp = {
  apiKey: "AIzaSyAuA7RaMqaokZ7h5bOf4DtrChBKroHjUBI",
  authDomain: "vcat-29547.firebaseapp.com",
  projectId: "vcat-29547",
  storageBucket: "vcat-29547.appspot.com",
  messagingSenderId: "681447378256",
  appId: "1:681447378256:web:a38beff0a11484f4e73055",
  measurementId: "G-VXK5BMYR3Z",
};

const app = initializeApp(firebaseApp);
const db = getFirestore(app);

const setLoggedInStatus = async () => {
  const StatusCommonId = moment().unix().toString();
  const Id = User?.user_id;
  const q = query(collection(db, "Status"), where("user_id", "==", Id));
  const querySnapshot = await getDocs(q);
  let statusId = "";
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    if (User.user_id === data.user_id) {
      statusId = data.StatusCommonId;
    }
  });
  if (statusId) {
    User.setStatusCommonId(statusId);
    const q = doc(db, "Status", statusId);
    await updateDoc(q, {
      status: true,
    });
  } else {
    User.setStatusCommonId(StatusCommonId);
    const lastSeen = moment().format("YYYY-MM-DD  hh:mm:ss");
    await setDoc(doc(collection(db, "Status"), StatusCommonId), {
      status: true,
      user_id: Id,
      lastSeen,
      StatusCommonId: StatusCommonId,
    });
  }
};

export { app, db, setLoggedInStatus };
