import React from "react";

const CommonTextField = ({
  id,
  label,
  required = false,
  error = "",
  placeholder,
  value = "",
  onChange,
  onFocus,
  maxLength,
  type = "text",
  className,
}) => (
  <div className={className}>
    <label className="d-flex align-center" htmlFor={id}>
      <span>
        {label}&nbsp;{required && <span className="asterisk">*</span>}
      </span>
    </label>
    <input
      id={id}
      type={type}
      className={`form-control ${error ? "validationError" : ""}`}
      placeholder={error || placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onFocus={onFocus}
      maxLength={maxLength}
    />
  </div>
);

export default CommonTextField;
